.domain-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5rem;
}
.domain-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.project-name-header button {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 14px !important;
}
.domain-header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.testing-false {
    display: none;
}
.testing-true {
    display: block;
}
.text-testing-false {
    display: block;
}
.text-testing-true {
    display: none !important;
}
.button-testing-true {
    opacity: 0.75;
    pointer-events: none;
    cursor: not-allowed;
}
.domain-container .element-wrapper .element-header.logs-heading {
    margin: 0 !important;
}
.table.table-padded thead tr th {
    vertical-align: top;
    font-size: 12px !important;
}
.result-container i {
    color: black !important;
}
.table.table-padded tbody tr:hover {
    transform: none !important;
    box-shadow: none !important;
}
.element-box-tp > .table-responsive {
    max-height: 750px;
}
.chart-select-forms, .chart-select-form {
    display: flex;
}
.chart-select-forms > select {
    display: block;
    width: 50%;
}
.chart-select-forms > select:first-child {
    margin-right: 5px;
}
.chart-select-forms > select:nth-child(2) {
    margin-left: 5px;
}
@media screen and (max-width: 769px) {
    .domain-page-header {
        flex-direction: column;
        justify-content: flex-start;
    }
    .project-dashboard-header-container {
        width: 100%;
    }
    .domain-buttons {
        justify-content: flex-start;
        width: 100%;
        margin-left: 3rem;
    }
    .domain-buttons button {
        width: calc(100% - 1.5rem);
    }
    .domain-header {
        word-break: break-word;
    }
    .chart-select-forms, .chart-select-form {
        flex-direction: column;
        margin-right: 1.5rem;
    }
    .chart-select-forms > select, .chart-select-form > select {
        display: block;
        width: 100%;
        margin: 0 0 10px 0 !important;
    }
}
