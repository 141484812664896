.test-logs-container {
    margin: 0 1.5rem;
    width: 95%;
}
.result-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px 4px 0;
    font-weight: bold;
}
.good-score, .trending-up-basic {
    color: #018642 !important;
}
.medium-score {
    color: #ae6032 !important;
}
.low-score, .trending-down-basic {
    color: #eb0f00 !important;
}