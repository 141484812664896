body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f1f1f1 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.main-container {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
}

.div-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: absolute;
    right: 0;
}
  
#main-content-area {
    padding-left: 300px;
    margin-top: 55px;
}

@media screen and (max-width: 1024px){
    #main-content-area {
        padding-left: 0;
    }
}

@media screen and (max-width: 768px){
    .main-container {
      flex-direction: column;
    }
  }