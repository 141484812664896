.menu-w {
    transition: all 200ms ease-in-out;
}

#side-menutrue {
    transform: translateX(0) !important;
}

.menu-position-side.menu-w {
    top: 55px;
    position: fixed;
}

.enabled-false {
    opacity: 0.3;
    pointer-events: none;
}

@media screen and (max-width: 1024px){
    .menu-w {
        width: 300px;
    }

    .menu-w ul.main-menu > li a span {
        display: block;
    }

    .menu-w ul.main-menu > li .icon-w {
        padding: 1rem 1rem 1rem 0rem;
    }

    .menu-w ul.main-menu > li.sub-header:first-child span {
        text-align: left;
    }

    .menu-position-side.menu-w {
        transform: translateX(-100%);
        transition: all 200ms ease-in-out;
    }

    #side-menutrue {
        transform: translateX(-100%) !important;
    }

    #side-menufalse {
        transform: translateX(0) !important;
        z-index: 2;
    }
}
