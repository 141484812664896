.table-element-box {
    margin: 20px auto;
}

.testing-reason-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.testing-reason-div input {
    width: 70%;
    margin-right: 10px;
    min-width: 120px;
}

#htaccess-btn {
  min-width: 110px;
}

#ssl-btn {
  min-width: 75px;
}

#index-btn {
  min-width: 82px;
}

.testing-reason-div button {
    height: 40px;
    width: 40px;
    margin: 0;
    padding: 0;
}

button {
    height: 40px;
}

.domain-container .element-wrapper .element-header {
    margin-top: 1rem !important;
    margin-left: 1.5rem !important;
}

.pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 0;
  color: #b3d8ff;
  padding: 10px 15px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paginationItem:focus {
    border: none;
    outline: none;
}

.paginationItem.null {
  pointer-events: none;
}

.prev,
.next {
  background: #047bf8;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 10px;
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
}

.prev:focus,
.next:focus {
    border: none;
    outline: none;
}

.paginationItem.active {
  border: 0;
  color: #047bf8;
  pointer-events: none;
  font-weight: bold;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  background: #b3d8ff;
  color: rgb(255, 255, 255);
}

.input-group input {
  height: 40px;
  border-radius: 0 4px 4px 0 !important;
}

.table.table-lightborder td {
  max-width: 500px;
  word-wrap: break-word;
}

input[type="checkbox"] {
  margin-right: 10px;
  height: 15px;
  width: 15px;
}

.table-responsive {
  overflow: auto !important;
}

.tool-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.thead-container-sync {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.thead-container-sync button {
  max-width: 45px;
  margin-top: 10px;
}

.table.table-lightborder thead tr th {
  vertical-align: top;
}

.url-table-data {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.url-table-data button {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.edit-url-input-false {
  display: none;
}

.url-container {
  position: relative;
  width: 100%;
}

.edit-url-input-true {
  width: 100%;
  height: calc(100% + 10px);
  margin-top: -5px;
  vertical-align: middle;
  z-index: 2;
  position: absolute;
}

.save-url-false {
  display: none !important;
}

.save-url-true {
  width: 12%;
  position: absolute;
  margin-top: -5px;
  height: calc(100% + 10px);
  vertical-align: middle;
  z-index: 2;
  right: -10px;
  display: block !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

i.is-loading-true {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

i.button-show-false {
  display: none !important;
}

i.button-show-true {
  display: block !important;
  margin-left: 0 !important;
}

.button-is-loading-false {
  display: block;
}

.button-is-loading-true {
  display: none;
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}