.logo-register-form {
    font-size: 100px;
    color: #1b55e2;
}

.auth-box-w {
    margin-top: 40px;
}

.buttons-w .btn-primary {
    width: 100%;
}

.register-link {
    margin-top: 20px;
}

.forgot-password-link {
    text-align: center;
}