.col-4 {
    padding: 0 10px 0 0;
    flex-direction: column;
}
.col-4 label {
    display: flex;
    align-items: center;
}
.col-4 p {
    font-weight: bold;
}
.select__menu {
    z-index: 3 !important;
}
@media screen and (max-width: 991px) {
    .row {
        flex-direction: column;
        margin-right: 0.5rem;
    }
    .col-4 {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}