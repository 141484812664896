.dashboard-container {
    padding: 40px;
}

.dashboard-box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.table-element-box {
    margin-top: 20px;
    width: calc(100% - 40px);
}

@media screen and (max-width: 1024px){
    .div-container {
        width: calc(100% - 100px);
    }
}
