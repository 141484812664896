.domain-container .project-name-header .element-wrapper .element-header {
    text-transform: lowercase;
}
.project-dashboard-header-container {
    display: flex;
    flex-direction: column;
}
.project-dashboard-header-container > div:nth-child(2) {
    margin: 0 1.5rem;
    display: flex;
    max-width: 500px;
    align-items: center;
}
.project-dashboard-header-container > div:nth-child(2) > button {
    margin-left: 1rem;
    width: 250px;
    height: 35px;
}
.project-name-header {
    justify-content: flex-start !important;
}
.report-row {
    display: flex;
}
.container-chart {
    margin: 0 1.5rem;
}
.container-chart .chart-select-forms, .container-chart .chart-select-form {
    margin: 0 !important;
    width: 100%;
}
.no-test-notice {
    margin: 40px auto;
    text-align: center;
}
@media screen and (max-width: 769px) {
    .project-dashboard-header-container > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
    .project-dashboard-header-container > div:nth-child(2) > button {
        margin: 0.5rem 0;
        width: 100%;
        height: 35px;
    }
    .domain-container .element-wrapper .element-header {
        font-size: 1.5rem;
    }
    .report-row {
        display: flex;
        flex-direction: column;
    }
}