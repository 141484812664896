.logo-label i {
    font-size: 30px;
}

.sidemenu-mobile-toggle i {
    color: #fff;
    font-size: 24px;
    padding: 0 1rem;
    cursor: pointer;
    display: none;
}

.avatar-w i {
    color: #fff;
    font-size: 24px;
}

.add-padding-sidemenu {
    padding-left: 300px;
}

.top-bar .top-menu-controls {
    height: 55px;
}

@media screen and (max-width: 1024px){
    .top-bar .top-menu-controls {
        justify-content: space-between;
        width: 100%;
    }

    .top-bar .logo-w.menu-size {
        display: none;
    }

    .sidemenu-mobile-toggle i {
        display: block;
    }
  }