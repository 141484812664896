.dashboard-box {
    width: 30%;
    margin: 32px 0;
}

.element-box.domain-list-class, .element-box.warnings-class, .element-box.errors-class {
    cursor: pointer;
}

.element-box.warnings-class {
    background: #fbe4a0;
}

.element-box.errors-class {
    background: #e65252;
}

.element-box.warnings-class.el-tablo:hover .value, .element-box.warnings-class.el-tablo:hover .label, 
.element-box.warnings-class.el-tablo .value, .element-box.warnings-class.el-tablo .label,
.element-box.domain-list-class.el-tablo:hover .value, .element-box.domain-list-class.el-tablo:hover .label, 
.element-box.domain-list-class.el-tablo .value, .element-box.domain-list-class.el-tablo .label  {
    color: #292b2c;
}

.element-box.errors-class.el-tablo:hover .value, .element-box.errors-class.el-tablo:hover .label, 
.element-box.errors-class.el-tablo .value, .element-box.errors-class.el-tablo .label {
    color: #fff;
}