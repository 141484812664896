.show-alert-false {
    transform: translateX(200%);
    transition: all 100ms ease-in-out;
    display: flex;
}

.show-alert-true {
    transform: translateX(0);
    transition: all 500ms ease-in-out;
    display: flex;
    width: calc(100% - 40px);
    right: 0 !important;
    margin: 0 20px;
}

.alert-neutral {
    color: #292b2c;
    border: 2px solid #7c7c7c;
    background-color: #ffffff;
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

#alert-container {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: auto;
    z-index: 5;
}

.clear-notification {
    border: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    margin: 0 0 0 10px;
}

.add-domain-id {
    position: absolute;
    bottom: 100px;
    left: 0px;
    width: calc(100% - 20px);
    margin: 0 10px;
}