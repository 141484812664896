.button-full-width {
  width: 100%;
  margin-top: 10px;
  height: 37px;
}
.check-label {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.domain-settings-container {
  display: flex;
  justify-content: space-around;
}
.domain-settings-container input[type="checkbox"] {
  margin-right: 2px !important;
}