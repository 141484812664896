.performance-report-box {
    margin: 2rem 1rem 0.5rem;
}
.el-tablo .trending {
    padding: 6px 15px;
    border-radius: 25px;
    font-weight: bold;
}
.good-overall-score {
    background-color: rgb(0, 204, 102, 0.25);
    color: rgb(0, 136, 0);
}
.avg-overall-score {
    background-color: rgb(255, 170, 51, 0.25);
    color: rgb(195, 51, 0);
}
.bad-overall-score {
    background-color: rgb(255, 51, 51, 0.25);
    color: rgb(204, 0, 0);
}
.element-box .row{
    margin-right: 0 !important;
}
@media screen and (max-width: 1475px) {
    .performance-report-box > .row {
        flex-direction: column;
    }
}