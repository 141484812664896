.dashboard-h6 {
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
}
h3 {
    margin-top: 0.5rem !important;
}
.dashboard-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard-header-container > div {
    width: 33%;
}
.dashboard-header-container > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    margin-right: 1.5rem;
}
.element-wrapper .element-header:after {
    display: none;
}
.element-wrapper .element-header {
    border-bottom: 0;
}
.project-box {
    width: 29%;
    min-width: 300px;
    height: 175px;
    margin: 1rem 1.5rem !important;
    display: inline-block;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
.project-box:hover {
    box-shadow: 0px 5px 13px rgb(126 142 177 / 12%);
    transition: all 200ms ease-in-out;
    transform: scale(1.02);
}
.projects-list .project-head {
    justify-content: center;
}
.projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.projects-list .project-head .project-title h5 {
    font-size: 0.75rem;
}
.search-projects-container {
    margin: 0 1.5rem;
}
@media screen and (max-width: 769px) {
    .projects-list .project-head {
        display: flex;
        align-items: center;
    }
    .dashboard-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .dashboard-header-container > div {
        width: 100%;
        margin: 0 !important;
    }
    .dashboard-header-container > div:nth-child(2), .dashboard-header-container > div:nth-child(3) {
        display: flex;
        justify-content: center;
    }
    .dashboard-header-container > div:nth-child(2) > input {
        width: 100%;
        margin: 0 1.5rem;
    }
    .dashboard-header-container > div:nth-child(3) > button {
        width: 100%;
        margin: 1rem 1.5rem;
    }
}